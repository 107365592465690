import { Recording } from "@/core/models";
import { getInputDevice } from "./devices";
import { Encoder } from "./encoder";

export default class {
  isRecording = false;
  bufferSize = 4096;
  isPause = false;
  duration = 0;
  savedDuration = 0;

  stream?: MediaStream;
  encoder?: Encoder;
  context?: AudioContext;
  processor?: ScriptProcessorNode;
  input?: MediaStreamAudioSourceNode;
  analyzer?: AnalyserNode;
  onupdate: (vol: number) => void;

  constructor(onupdate: (vol: number) => void = () => {}) {
    this.onupdate = onupdate;
  }

  async start() {
    const inputDevice = getInputDevice();
    const constraints: MediaStreamConstraints = {
      video: false,
      audio: {
        channelCount: 1,
        echoCancellation: false,
        deviceId: inputDevice ? { exact: inputDevice } : undefined,
      },
    };

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.stream = stream;
      this.encoder = new Encoder();
      this._micCaptured(stream);
    } catch (error) {
      console.log("Mic error", error);
    }
  }

  async stop() {
    this.stream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());

    this.input?.disconnect();
    this.processor?.disconnect();
    this.duration = this.context?.currentTime || this.savedDuration;
    this.context?.close();

    const blob = this.encoder?.finish();
    if (!blob) return;

    const now = new Date();
    const record = {
      blob: blob,
      recordedAt: now.toISOString(),
      title: `Recorded on ${now.toLocaleString()}`,
    } as Recording;

    this.savedDuration = 0;
    this.duration = 0;
    this.isRecording = false;

    return record;
  }

  pause() {
    this.stream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    this.input?.disconnect();
    this.processor?.disconnect();
    this.duration = this.context?.currentTime || this.savedDuration;
    this.context?.close();

    this.savedDuration = this.duration;
    this.isPause = true;
  }

  _micCaptured(stream: MediaStream) {
    // @ts-ignore
    this.context = new (window.AudioContext || window.webkitAudioContext)();
    this.duration = this.savedDuration;
    this.analyzer = this.context.createAnalyser();
    this.input = this.context.createMediaStreamSource(stream);
    this.processor = this.context.createScriptProcessor(this.bufferSize, 1, 1);
    this.stream = stream;

    this.analyzer.fftSize = 64;
    this.analyzer.smoothingTimeConstant = 0.3;

    this.input.connect(this.analyzer);
    this.analyzer.connect(this.processor);
    this.processor.connect(this.context.destination);
    this.processor.onaudioprocess = (ev: AudioProcessingEvent) => {
      // Toggle if recording
      if (!this.isRecording || (this.isRecording && this.isPause)) {
        this.isPause = false;
        this.isRecording = true;
      }

      // encode sample
      const sample = ev.inputBuffer.getChannelData(0);
      this.encoder?.encode([sample]);

      // get duration
      this.duration = this.savedDuration + (this.context?.currentTime || 0);

      // get volume
      if (!this.analyzer) return;
      const buffer = new Uint8Array(this.analyzer.frequencyBinCount);
      this.analyzer.getByteFrequencyData(buffer);
      const sum = buffer.reduce((cum, curr) => cum + curr, 0);
      const vol = sum / buffer.length;
      this.onupdate(vol);
    };

    this.input?.connect(this.processor);
    this.processor.connect(this.context.destination);
  }
}
